/**
 * App configuration
 */
class APPConfig {
  /**
   * api url endpoint
   */
  static API_URL: string = process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : 'https://dev-api.harbingerschool.com/api/v1';
}

export default APPConfig;
