import axios from 'axios';
import APPConfig from './config';

class APIService
{
  protected baseURL:string;
  
  constructor( url: string = ''){
    //set the default api url properly
    this.baseURL = url && url.length > 0 ? url : APPConfig.API_URL;
    // let currentConfig:any = null;
    // Add a request interceptor
    axios.interceptors.request.use( async (config)  => {
      //get the token
      const token = localStorage.getItem('token');
      //get the current config so that we can call the method if there is a token expired error
      //do not get the config for refresh token call, because it means that it's refreshing the token
      // if(this.baseURL + '/auth/refresh-token' !==  config.url)
      //     currentConfig = config;
  
      if(token)
        config.headers.Authorization = token;

      return config;
    });
  }

  buildURL = (method:string):string => {
    return this.baseURL + '/' + method;
  }

  get = async (method:string) => {
    return await axios.get(this.buildURL(method));
  }

  post = async (data:any, method:string) => {
    try {
      return await axios.post(this.buildURL(method), data);
    } catch(error) {
      return Promise.resolve(error.response);
    }
      
  }

  patch = async (data:any, method:string) => {
    return await axios.patch(this.buildURL(method), data);
  }

  getPatch = async (method:string) => {
    return await axios.patch(this.buildURL(method));
  }
  
  put = async (data:any, method:string) => {
    return await axios.put(this.buildURL(method), data);
  }

  remove = async (method:string) => {
    return await axios.delete(this.buildURL(method));
  }

}

export default APIService