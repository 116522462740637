import React, { Component, useState, useEffect } from "react";
import { Menu, Tooltip, Row, Space, Col } from 'antd';
import { withRouter, useHistory } from "react-router-dom";
import { ExportOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFeather,
  faHammer,
  faUsers,
  faUniversity,
  faCog,
  faDownload,
  faFolderOpen,
  faSpa, faUserFriends,
  faComment, faGraduationCap,
  faFileAlt,
  faHashtag,
  faBullhorn,
  faClipboard,
  faUserClock,
  faFileSignature,
  faHome,
  faBook,
  faProjectDiagram,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import image from '../images/profile-image.png';
import harbingerImage from '../images/harbinger.png';
import { titleCase } from "../Utility/TextTransformUtils";
import AdminService from "../services/AdminService";

const SideMenuView = ({ siderCollapsed, collapseToggle }: any) => {

  const history = useHistory();
  const path = window.location.pathname;
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  const [student, setStudent]: any = useState();
  const [studentImage, setStudentImage]: any = useState(null);

  useEffect(() => {
    const initialize = async () => {
      if (localStorage.getItem('token')) {
        const admissionService = new AdminService();
        const response = await admissionService.getSession();
        setStudent(response);
        setStudentImage(response.image)
      }
    };
    initialize();
  }, [path]);

  const onToggleMenus = (action: string) => {
    setCurrentPath(action);
    history.push(action);
  }

  const onLogout = () => {
    localStorage.clear();
    window.location.reload(false);
  };

  return (
    <div className="sidebar sidebar-admin">
      <div
        className="flex-row items-center justify-center"
        style={{ paddingBottom: 20 }}
      >
        <img src={harbingerImage} width="120" alt="profile-img" onClick={() => onToggleMenus('/')}
          style={{ pointerEvents: "auto", cursor: "pointer" }} />
      </div>
      {/* <div className="sideToggle">
          <div onClick={collapseToggle} className="toggle-icon">
              {siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined/>}    
          </div>
      </div> */}
      <Menu theme="dark" defaultSelectedKeys={[currentPath]} mode="inline" style={{ background: "transparent", overflowY: 'auto', height: 'calc(100% - 106px)' }}>

        <Menu.Item
          key="/"
          icon={<FontAwesomeIcon icon={faHome} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/')}
        >
          <span className="font-14">Home</span>
        </Menu.Item>
        <Menu.Item
          key="/applications"
          icon={<FontAwesomeIcon icon={faFileSignature} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/applications')}
        >
          <span className="font-14">Applications</span>
        </Menu.Item>
        <Menu.Item
          key="/cohorts"
          icon={<FontAwesomeIcon icon={faUsers} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/cohorts')}
        >
          <span className="font-14">Cohorts</span>
        </Menu.Item>

        <Menu.Item
          key="/interview-prep"
          icon={<FontAwesomeIcon icon={faUserClock} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/interview-prep')}
        >
          <span className="font-14">Interview Prep</span>
        </Menu.Item>

        <Menu.Item
          key="/quiz"
          icon={<FontAwesomeIcon icon={faFeather} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/quiz')}
        >
          <span className="font-14">Quiz</span>
        </Menu.Item>

        <Menu.Item
          key="/courses"
          icon={<FontAwesomeIcon icon={faLightbulb} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/courses')}
        >
          <span className="font-14">Courses</span>
        </Menu.Item>

        <Menu.Item
          key="/gradebook"
          icon={<FontAwesomeIcon icon={faBook} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/gradebook')}
        >
          <span className="font-14">Gradebook</span>
        </Menu.Item>

        <Menu.Item
          key="/reports"
          icon={<FontAwesomeIcon icon={faFileAlt} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/reports')}
        >
          <span className="font-14">Reports</span>
        </Menu.Item>
        <Menu.Item
          key="/attendance"
          icon={<FontAwesomeIcon icon={faClipboard} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/attendance')}
        >
          <span className="font-14">Attendance</span>
        </Menu.Item>

        <Menu.Item
          key="/office-hours"
          icon={<FontAwesomeIcon icon={faFileAlt} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/office-hours')}
        >
          <span className="font-14">Office Hours</span>
        </Menu.Item>

        <Menu.Item
          key="/announcements"
          icon={<FontAwesomeIcon icon={faBullhorn} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/announcements')}
        >
          <span className="font-14">Announcements</span>
        </Menu.Item>

        <Menu.Item
          key="/settings"
          icon={<FontAwesomeIcon icon={faCog} style={{ width: 22, fontSize: 16, marginRight: 10 }} />}
          onClick={() => onToggleMenus('/settings')}
        >
          <span className="font-14">Settings</span>
        </Menu.Item>
      </Menu>
      <div className="logout">
        <Row justify="space-between" className="width-max" style={{ flexWrap: 'nowrap' }}>
          <Col>
            <Space>
              <div className="profile-icon flex-col items-center justify-center">
                {studentImage ? <img style={{ borderRadius: 50 }} src={studentImage} alt="profile-img" width="30" height="30" /> : student ? student.firstName && student.lastName ? `${student.firstName.substring(0, 1)}${student.lastName.substring(0, 1)}` : student.firstName.substring(0, 1) : 'Student'}
              </div>
              <div className="flex-col">
                <span style={{ lineHeight: '14px' }}>
                  {student ? student.firstName && student.lastName ? `${student.firstName} ${student.lastName}` : student.firstName : 'Student'}
                </span>
                <span className="color-gray font-10">{student ? titleCase(student.role) : 'Student'}</span>
              </div>
            </Space>
          </Col>
          <Col >
            <Tooltip placement="bottomRight" title={'Logout'}>
              <ExportOutlined onClick={onLogout} style={{ fontSize: 16 }} className="logout-icon" />
            </Tooltip>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default withRouter(SideMenuView);