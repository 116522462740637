import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// REACT CALENDAR 
import 'react-calendar/dist/Calendar.css';
import './assets/scss/_main-dir.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: 'b11bc5454ecfb529f3ac911cf4ff7ae7',
  plugins: [new BugsnagPluginReact(React)]
})
const ErrorBoundary = Bugsnag.getPlugin('react')

ReactDOM.render(
  // @ts-ignore
  <ErrorBoundary>
    <App />
  </ ErrorBoundary>,
  document.getElementById('root')
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
