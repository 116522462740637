import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import LoginView from './pages/Login/LoginView';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LayoutView from './layout/LayoutView';
import lazyLoader from './hoc/LazyLoader';
import Loader from './components/Loader';
import ProtectedRoute from './hoc/ProtectedRoute';
import LoginService from './services/LoginService';
import PublicRoute from './hoc/PublicRoute';
import DialogUtils from './Utility/DialogUtils';
import { isArray } from "util";

const Home: any = lazyLoader(() => import('./pages/Home/HomeListView'));
const Applications: any = lazyLoader(() => import('./pages/Applications/ApplicationListView'));
const StudentApplication: any = lazyLoader(() => import('./pages/Applications/ApplicationView'));
const Cohorts: any = lazyLoader(() => import('./pages/Cohorts/CohortListView'));
const InterviewPrep: any = lazyLoader(() => import('./pages/InterviewPrep/InterviewPrepListView'));
const InterviewPrepChapter: any = lazyLoader(() => import('./pages/InterviewPrep/QuestionsListView'));
const InterviewPrepAnswers: any = lazyLoader(() => import('./pages/InterviewPrep/AnswerListView'));
const Students: any = lazyLoader(() => import('./pages/Cohorts/StudentListView'));
const StudentProfile: any = lazyLoader(() => import('./pages/Cohorts/StudentView'));
const StudentsAttendance: any = lazyLoader(() => import('./pages/Attendance/AttendanceView'));
const Attendance: any = lazyLoader(() => import('./pages/Attendance/AttendanceListView'));
const StudentsAppointments: any = lazyLoader(() => import('./pages/Appointment/Appointments'));
const Announcements: any = lazyLoader(() => import('./pages/Announcement/Announcements'));
const Settings: any = lazyLoader(() => import('./pages/Settings/SettingsView'));
const Reports: any = lazyLoader(() => import('./pages/Reports/ReportsView'));
const currentApplicants = lazyLoader(() => import('./pages/Dashboard/CurrentApplicants'));
const viewApplication = lazyLoader(() => import('./pages/Dashboard/ViewApplicant'));
const paymentView = lazyLoader(() => import('./pages/Payments/PaymentsView'));
const applicationView2 = lazyLoader(() => import('./pages/Students/ApplicationView'));
const profileView = lazyLoader(() => import('./pages/Students/ProfileView'));
const studentOldView = lazyLoader(() => import('./pages/Students/StudentsView'));
const attendanceOldView = lazyLoader(() => import('./pages/Attendance/AttendanceOldView'));
const settings = lazyLoader(() => import('./pages/Settings/Settings'));

// PROJECTS 
const courseList = lazyLoader(() => import('./pages/CoursesView/CoursesListView'));
const chaptersList = lazyLoader(() => import('./pages/CoursesView/ChaptersView'));
const lessonsList = lazyLoader(() => import('./pages/CoursesView/LessonsView'));
const projectsList = lazyLoader(() => import('./pages/CoursesView/ProjectListView'));

// GRADEBOOK 
const gradebook = lazyLoader(() => import('./pages/Gradebook/GradebookVIew'));
const gradebookStudents = lazyLoader(() => import('./pages/Gradebook/StudentsView'));
const gradebookProjects = lazyLoader(() => import('./pages/Gradebook/StudentProjectView'));
const gradebookLessons = lazyLoader(() => import('./pages/Gradebook/StudentLessonView'));

// QUIZ
const Quiz: any = lazyLoader(() => import('./pages/Quiz/QuizListView'));
const QuizChapter: any = lazyLoader(() => import('./pages/Quiz/QuestionsListView'));
const QuizAnswers: any = lazyLoader(() => import('./pages/Quiz/AnswerListView'));

function App() {
  const [loader, setLoader] = useState(false);
  const authService = new LoginService();

  useEffect(() => {
    axios.interceptors.request.use(function (config) {
      setLoader(true);
      return config
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      setLoader(false);
      return response;
    }, async (error) => {
      if (error.response) {
        showError(error.response.data.errors);
      } else {
        showError(null);
      }

      setLoader(false);
      return Promise.reject(error);
    });
  }, []);

  const showError = (errors: any) => {
    let content = null;
    if (isArray(errors)) {
      content = (
        <div>
          {errors.map((item: any, index: number) => { return <div key={Math.random().toString(6)}>{item.message}</div> })}
        </div>
      );
    } else {
      content = (
        <div style={{ whiteSpace: 'pre-line' }}>
          {errors ? errors : 'There was an error with your network. Please try again later.'}
        </div>
      );
    }

    DialogUtils.error(content);
  }

  return (
    <div>
      <Router>
        <Switch>
          <PublicRoute
            auth={{ isLoggedIn: () => authService.isLoggedIn() }}
            exact
            path="/admin-login"
            component={LoginView}
          />
          <LayoutView>
            <ProtectedRoute exact path="/" component={Home} />
            <ProtectedRoute exact path="/applications" component={Applications} />
            <ProtectedRoute exact path='/applications/applicant/:id' component={StudentApplication} />
            <ProtectedRoute exact path="/cohorts" component={Cohorts} />
            <ProtectedRoute exact path='/cohorts/:cohort/students' component={Students} />
            <ProtectedRoute exact path='/cohorts/:cohort/student/:id' component={StudentProfile} />
            <ProtectedRoute exact path="/interview-prep" component={InterviewPrep} />
            <ProtectedRoute exact path="/interview-prep/:chapter" component={InterviewPrepChapter} />
            <ProtectedRoute exact path="/interview-prep/:answers/answers" component={InterviewPrepAnswers} />

            {/* QUIZ */}
            <ProtectedRoute exact path="/quiz" component={Quiz} />
            <ProtectedRoute exact path="/quiz/:chapter" component={QuizChapter} />
            <ProtectedRoute exact path="/quiz/:answers/answers" component={QuizAnswers} />

            <ProtectedRoute exact path='/attendance' component={StudentsAttendance} />
            <ProtectedRoute exact path='/attendance/:cohort/:id' component={Attendance} />
            <ProtectedRoute exact path='/office-hours' component={StudentsAppointments} />
            <ProtectedRoute exact path='/announcements' component={Announcements} />
            <ProtectedRoute exact path='/settings' component={Settings} />
            <ProtectedRoute exact path='/reports' component={Reports} />

            {/* PROJECTS  */}
            <ProtectedRoute exact path='/courses' component={courseList} />
            <ProtectedRoute exact path='/courses/:id/chapters' component={chaptersList} />
            <ProtectedRoute exact path='/chapter/:id/lessons' component={lessonsList} />
            <ProtectedRoute exact path='/lesson/:id/projects' component={projectsList} />

            {/* GRADEBOOK */}
            <ProtectedRoute exact path='/gradebook' component={gradebook} />
            <ProtectedRoute exact path='/gradebook/:id/students' component={gradebookStudents} />
            <ProtectedRoute exact path='/gradebook/:id/projects' component={gradebookProjects} />
            <ProtectedRoute exact path='/gradebook/project-submission/:id' component={gradebookLessons} />
            {/* <ProtectedRoute exact path='/applicant/:id' component={viewApplication} /> */}
            {/* <ProtectedRoute exact path='/student/:id' component={profileView}/> */}
            {/* <ProtectedRoute exact path='/application/:id' component={applicationView2} /> */}
            {/* <ProtectedRoute exact path='/payments' component={paymentView}/> */}
            {/* <ProtectedRoute exact path='/students' component={studentOldView} /> */}
            {/* REDIRECT TO HOME IF NO PAGE FOUND */}
            <Route render={props => {
              const { key } = props.location;
              const isLoggedIn = authService.isLoggedIn();
              if (isLoggedIn && !key) {
                return <Redirect from='*' to="/" />
              } else if (!isLoggedIn) {
                return <Redirect from='*' to="/admin-login" />
              }
            }
            } />
          </LayoutView>
        </Switch>
      </Router>
      <Loader show={loader} />
    </div>
  );
}

export default App;
