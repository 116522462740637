import APIService from "./APIService";

class LoginService extends APIService {
  login = async ({ username, password }: any) => {
    const result = await this.post(
      { username, password },
      "admin-portal/login"
    );
    return result ? result.data : null;
  };

  isLoggedIn = () => {
    return localStorage.getItem("token") ? true : false;
  };
}

export default LoginService;
