import React, { useState } from "react";
import { Layout } from "antd";
import SideMenuView from "./SideMenuView";

const { Sider, Content } = Layout;

const LayoutView = (props: any) => {
  const [collapsed, isCollaped]: any = useState(false);

  const toggleCollapse = () => {
    isCollaped(!collapsed);
  };

  return (
    // <div>
    //   <AdminNav setSearch={setSearch} />
    //   <div className="layout-view"></div>
    //   <SearchProvider value={search}>{props.children}</SearchProvider>
    // </div>

    <Layout>
      <Layout>
        <Sider 
          theme="light" 
          trigger={null} 
          collapsible 
          collapsed={collapsed} 
          onCollapse={toggleCollapse}
          style={{ background: "rgb(244, 245, 247)" }}
          width={'15rem'}
        >
          <SideMenuView siderCollapsed={collapsed} collapseToggle={toggleCollapse} />
        </Sider>
        <Content className="content-container" style={{overflow: 'auto'}}>
          {props.children}
        </Content>
      </Layout>  
    </Layout>   
  );
};

export default LayoutView;
