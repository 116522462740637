import React from "react";
import { Spin } from 'antd';

const Loader = (props: any) => {
  return (
    <div className={["loader", props.show ? "active" : ""].join(" ")}>
      <Spin size="large" />
    </div>
  );
};

export default Loader;
