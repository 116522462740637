/**
 * 
 * @param rule 
 * @param value 
 */
export const validateEmail = async (rule:any, value:string) => 
{
    if(!value || value.length === 0)
            return Promise.resolve();

    return (/^(([A-Za-z0-9]+_)|([A-Za-z0-9]+\-)|([A-Za-z0-9]+\.)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/).test(value) ? Promise.resolve() : Promise.reject('Email is not a valid email.');    
};
