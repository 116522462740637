import React from 'react';
import { Button, Form } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { History } from 'history';
import BaseInput from '../../components/BaseInput';
import { validateEmail } from '../../Utility/ValitdatorUtils';
import LoginService from '../../services/LoginService';
import image from '../../images/profile-image.png';
import harbingerImage from '../../images/harbinger.png';

interface ILoginViewProps {
  history:History
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tail = {
  wrapperCol: {span: 24 },
}

const LoginView = (props:ILoginViewProps) => {

  const onSubmitSuccess = async (values: any) => {
    const api = new LoginService();
    const response = await api.login(values);

    if (response) {
      if (!response.errors) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("userId", response.user._id);
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem(
          "name",
          response.user.firstName + " " + response.user.lastName
        );
        props.history.push("/");
      }
    }
  }

  const onSubmitFailed = (errorInfo: any) => {
    console.log(errorInfo);
  }

  return (
    <div className="login">
      <div className="login-container">
        <div className="login-container__welcome font-18 flex-col items-center" style={{ marginBottom: 30 }}>
          <div style={{ marginBottom: 15 }}>
          <img src={harbingerImage} width="150" alt="profile-img" />  
          </div>
          Log in to get started today.
        </div>
        <Form {...layout} style={{ textAlign: 'left'}}
          name="formLogin"
          className="flex-col items-center"
          onFinish={onSubmitSuccess}
          onFinishFailed={onSubmitFailed}
        >
          <Form.Item 
            name="username"
            rules={[{ required: true, message: 'Please input your email!' }, {validator: validateEmail}]}
            style={{ width: "100%" }}
          >      
            <BaseInput 
              placeholder="Email"
              prefix={
                <UserOutlined className="site-form-item-icon" />
              }
              tabIndex={1}
            />        
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            style={{ width: "100%" }}
          >
            <BaseInput.Password id="txtPassword"
              placeholder="Password"
              prefix={
                <LockOutlined className="site-form-item-icon" />
              } 
              tabIndex={2}
            />
          </Form.Item>
          <Form.Item {...tail}>
            <Button type="primary" htmlType="submit" style={{ marginTop: 10 }}>
              Log in
            </Button>
          </Form.Item>    
        </Form>
      </div>
    </div>
  );
}

export default LoginView