import APIService from "./APIService";

class AdminService extends APIService {
  getSession = async () => {
    const result = await this.get("student/apply/session");
    return result ? result.data : null;
  };
}

export default AdminService;
